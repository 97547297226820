.icon-slide-in-container {
  position: fixed;
  right: 0;
  top: 20vh;
  width:1px;
  overflow: visible;
  z-index: 3;
  @include breakpoint(medium down) {
    display: none;
  }
}

.slide-in-item {
  position: relative;
  float: right;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transform: translateX(210px);
  background: white;
  font-size: rem-calc(15);
  color: $white;
  width: rem-calc(278);
  border-radius: 50px 0 0 50px;
  box-shadow: -0 3px 3px rgba(0,0,0,0.2);
  margin-bottom: 10px;
  transition: all 0.4s;
  &:hover {
    transform: translateX(0%);
    background-color: $green;
    .img-grayscale {
      filter: grayscale(0%);
      opacity: 1;
    }
  }

  &.extra-slide {
    border-radius: 5px 0 0 5px;
  }

  .img-grayscale {
    opacity: 0.4;
    filter: grayscale(100%);
    transition: all 0.4s;
  }
  .text {
    padding: 9px;
    text-align: left;
    p {
      margin: 0;
    }
  }
}



#verhalten-modal {
  background-color: $green;
  padding-top: rem-calc(90);
  .reval-icon {
    text-align: center;
    color: $white;
    img {
      width: rem-calc(110);
    }
    .text p {
      margin: 0;
    }
  }

  .close-button {
    top: -1px;
    right: rem-calc(19);
    .svg-icon {
      width: rem-calc(36);
      .icon {
        fill: $white;
      }
    }
  }
}