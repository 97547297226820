.video-container {
  background-color: $white;
  margin-bottom: 2.2em;
  .responsive-embed {
    margin: 0;
  }
  .caption {
    font-weight: 700;
    padding: 1.7em 2em 2em 2em;
    @include font-size(1.125,1.875);
  }
}