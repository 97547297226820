html.is-reveal-open {
  //position: unset;
  //z-index: -1;
}

.reveal-content-container {
  padding: 3em;
  .close-button {
    font-size: 1em;
    padding: 0.5em;
    .svg-icon {
      height: 1.7em;
      width: 1.6em;
      .icon {
        fill: $brown;
      }
    }
    &:hover {
      .svg-icon {
        .icon {
          fill: $black;
        }
      }
    }
  }
  .article-img {
    margin-bottom: 2em;
  }
  .reveal-content {
    @include font-size(0.9375,1.125);
    h3 {
      margin-top: 2em;
    }
  }
}