.logo {
  position: relative;
  a {
    display: inline-block;
    img {
      width: rem-calc(130);
      height: auto; //immer width und height in PX angeben (IE)
      @include breakpoint(large) {
        width: rem-calc(140);
      }
      @include breakpoint(xlarge) {
        width: rem-calc(200);
      }
    }
  }
}

.header-overlay {
  .logo {
    padding-top: rem-calc(15);
    background: linear-gradient(0deg, rgba(255,255,255,0) 80%, rgba(255,255,255,1) 81%, rgba(255,255,255,1) 100%);
    @include breakpoint(large) {
      padding-top: rem-calc(20);
    }
  }
}

.header-underlay {
  .logo {
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(10);
  }
}