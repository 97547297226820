.button {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.btn-submit {
  background-color: $white;
  color: $black;
  text-transform: none;
  font-weight: 500;
  border: 2px solid $white;
  letter-spacing: 0;
  font-size: 0.6em;
  padding: 0.8em 1.2em;
  margin-top: rem-calc(25);
  transition: all .15s ease-in-out;
  &:hover, &:focus {
    background-color: $beige;
    color: $white;
  }
}