.accordion {
  width: rem-calc(990);
  max-width: 100%;
  margin: 0 auto;
  border-top: 1px solid $brown;
  @include font-size(1,1.125);
}

.accordion-item {
  border-bottom: 1px solid $brown;
  .accordion-title {
    text-align: center;
    padding-left: 0;
    padding-righ: 0;
    font-weight: 500;
    &:hover {
      color: inherit;
      opacity: 0.7;
    }
    &:focus {
      color: $white;
    }
  }
  .accordion-content {
    padding-top: 0;
    text-align: center;
  }
}

.bg-brown {
  .accordion {
    border-color: $white;
  }
  .accordion-item {
    border-color: $white;
  }
  .accordion-content {
    color: $white;
  }
}