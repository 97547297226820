img {
  image-rendering: -webkit-optimize-contrast;
}

.rounded {
  aspect-ratio: 1;
}

figure {
  picture {
    img {
      width: auto;
    }
  }
}