.search-desktop {
  font-size: rem-calc(14);
  padding-top: rem-calc(35);
  padding-right: rem-calc(20);
  text-align: right;
}

#search-modal {
  padding: rem-calc(4em 1em);
  .close-button {
    font-size: 0.8em;
    margin-top: rem-calc(10);
    margin-right: rem-calc(5);
  }
}

#modal-search {
  margin: 0;
  border: none;
}

#search-modal {
  .sisea-search-form {
    .input-group {
      margin-bottom: 1em;
      box-shadow: 0 8px 8px rgba($black,0.25);
      padding: rem-calc(20);
    }
    .input-group-button {
      padding: rem-calc(5 12);
      background: $white;
      color: $beige;
      button {
        font-size: 0.6em;
        cursor: pointer;
      }
      .icon {
        fill: $beige;
      }
    }
  }
}

.sisea-search-form.search-landing {
  margin-bottom: rem-calc(50);
  .input-group {
    margin-bottom: 1em;
    box-shadow: 0 8px 8px rgba($black,0.25);
    padding: rem-calc(20);

    .input-group-field {
      font-size: 1.2em;
      margin: 0;
      border: none;
    }
  }

  .input-group-button {
    padding: rem-calc(5 12);
    background: $white;
    color: $beige;
    button {
      font-size: 0.6em;
      cursor: pointer;
    }
    .icon {
      fill: $beige;
    }
  }
}

.simplesearch-result {
  border-top: 1px solid $brown;
  display: grid;
  padding-top: rem-calc(10);
  @include breakpoint(medium) {
    grid-template-columns: 1fr 3fr 1fr;
    grid-gap: rem-calc(20);
  }
  > a {
    margin-bottom: 10px;
    font-weight: bold;
    align-items: center;
    display: flex;
    height: max-content;
    justify-content: flex-end;
    svg {
      margin-left: rem-calc(6);
    }
  }
}

.simplesearch-paging {
  margin-bottom: rem-calc(20);
}

.search-mobile {
  position: fixed;
  top: rem-calc(18);
  right: rem-calc(80);
  button {
    cursor: pointer;
  }
}