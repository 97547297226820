.grid-5 {
/*  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;*/
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  .grid-item {
    flex-basis: calc(100% / 2);
    @include breakpoint(medium) {
      flex-basis: calc(100% / 3);
    }
    @include breakpoint(large) {
      flex-basis: calc(100% / 5);
    }
  }
  .text {
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.svg-data {
  a {
    svg {
      transition: all .15s ease-in-out;
      .circle {
        fill: $white;
        transition: all .15s ease-in-out;
      }
      .icon {
        fill: $beige;
        transition: all .15s ease-in-out;
        &--summer {
          fill: $olive;
        }
        &--winter {
          fill: $turquoise;
        }
        &--doc {
          fill: $brown;
        }
      }
      .innercircle {
        fill: $white;
      }
      .outercircle {
        fill: $white
      }
    }
    &:hover {
      svg {
        transform: scale(1.05);
        .circle {
          fill: $beige;
        }
        .icon {
          fill: $white !important;
        }
      }
    }
  }
}

.inpage-txt {
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}