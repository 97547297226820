#menu-desktop,
#menu-offcanvas {
  .menu {
    font-size: rem-calc(25);
    li {
      a {
        font-weight: 300;
        &:hover {
          color: $beige;
        }
      }
      &.active {
        > a {
          font-weight: 700;
          &:hover {
            color: $beige;
          }
        }
      }
      &.is-active a {
        color: black;
        &:hover {
          color: $beige;
        }
      }
    }
  }
  .submenu {
    font-size: rem-calc(15);
    display: block;

    background-color: transparent;
    a {
      &:hover {
        color: $beige;
      }
    }
  }
}

#menu-desktop {
  padding-left: rem-calc(180);
  @include breakpoint(1200) {
    padding-left: rem-calc(150);
  }
  @include breakpoint(xlarge) {
    padding-left: rem-calc(280);
  }
  .menu {
    font-size: rem-calc(18);
    li {
      a {
        padding-top: rem-calc(40);
        white-space: nowrap;
        text-align: center;
        background-color: $white;
        position: relative;
        z-index: 9;
        @include breakpoint(1200) {
          padding-left: 1.8em;
          padding-right: 1.8em;
        }
      }
    }
  }
  .submenu {
    z-index: 8;
    opacity: 0;
    margin-left: 50%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: bottom center;
    border: none;
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(50);
    transition: all .25s cubic-bezier(.09,.02,.46,.64);
    transform: translate(-50%, -60%);
    filter: drop-shadow(0px 4px 3px rgba(0,0,0,.25));
    background-image: url('../images/BG-subnavi.svg');
    &.js-dropdown-active {
      opacity: 1;
      transform: translate(-50%, 0%);
    }

    li {
      a {
        padding-top: rem-calc(11);
      }
    }

  }
}

@include breakpoint(medium down) {
  #menu-desktop {
    display: none;
  }
}

#menu-offcanvas {
  background-color: white;
  .menu {
    margin-top: rem-calc(80);
    background-color: transparent;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 1114px 2200px;
    text-align: center;
    > li {
      margin-left: rem-calc(30);
      margin-right: rem-calc(30);
      font-size: rem-calc(25);
      border-bottom: 1px solid $brown;
      padding-top: rem-calc(4);
      padding-bottom: rem-calc(4);
      &.last {
        border: none;
      }
      a {
        background-color: transparent;
        &:hover {
          color: $beige;
          font-weight: bold;
        }
      }
      > ul {
        margin-top: 0;
        li {
          font-size: rem-calc(20);
          border: none;
          a {
            padding-top: rem-calc(5);
            padding-bottom: rem-calc(13);
            &:hover {
              color: $beige;
            }
          }
        }
      }
      &.verhalten {
        padding: rem-calc(60 20 84 20);
        button {
          text-transform: uppercase;
          color: $white;
          font-weight: 700;
          cursor: pointer;
        }
      }
    }
  }
}