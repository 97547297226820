.grid-container {
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
  @include breakpoint(medium) {
    padding-left: rem-calc(40);
    padding-right: rem-calc(40);
  }
  @include breakpoint(large) {
    padding-left: rem-calc(80);
    padding-right: rem-calc(80);
  }
}

.flora-wrapper {
  //border-bottom: 1px solid $brown;
  margin-bottom: 2em;
}

.flora-entry {
  border-top: 1px solid $brown;
  padding: 1.5em 0;
  @include font-size(0.9375,1.25);
  font-weight: 400;
  h4 {
    @include font-size(0.9375,1.25);
    font-weight: 500;
    text-transform: uppercase;
    line-height: $global-lineheight;
    margin: 0;
  }
}
