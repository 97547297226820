//global font-size (small, medium, large)
// main {
//   font-size: rem-calc(18);
//   @include breakpoint(medium) {
//     font-size: rem-calc(21);
//   }
//   @include breakpoint(large) {
//     font-size: rem-calc(24);
//   }
// }

main {
  //Dynamic Font Size
  @include font-size(1.25,1.875);
}

.txt-small {
  @include font-size(1.0625,1.25);
}


//@include breakpoint(1800px) {
//  :root {
//    font-size: 20px;
//  }
//}

a:focus {
  outline: none;
}

h1 {
  @include font-size(2.8125,6.25);
  text-transform: uppercase;
  font-weight: 700;
}
@media screen and (max-width: 440px) {
  h2 {
    hyphens: auto;
  }
}

h2 {
  @include font-size(2.1875,3.75);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1.5em;
}
.multi-column h2 {
  text-align: left;
}

h3 {
  @include font-size(1.25,1.875);
  margin-bottom: 1em;
}

h4 {
  font-weight: 500;
  margin: 1.6em 0 1em 0;
  @include font-size(1.25,1.875);
}

.richtext {
  font-weight: 400;
  &.lead {
    font-weight: 500;
  }
  &.smallfont {
    p {
      font-size: rem-calc(20);
    }
  }
}

.multi-column {
  .richtext {
    font-weight: 300;
    font-size: rem-calc(20);
    p {
      line-height: 1.5;
    }
  }
}

.lead {
  @include font-size(1.25,1.875);
  font-weight: 500;
}

p {
  margin-bottom: 1.4em;
}

.underline {
  text-decoration: underline;
  text-underline-offset: 0.3em;
}