@import "mixins/transition";
@import "mixins/dynamicfontsize";

//Atoms
@import "atoms/address";
@import "atoms/buttons";
@import "atoms/img";
@import "atoms/lists";
@import "atoms/logo";
@import "atoms/typo";
@import "atoms/icons";
@import "atoms/video";

//Molecules
@import "molecules/cookie";
@import "molecules/offcanvas";
@import "molecules/form";
@import "../../node_modules/swiper/swiper-bundle.css";
//@import "../../node_modules/zoomist/src/scss/index.scss";
@import "molecules/swiper";
@import "molecules/search";
@import "molecules/card";
@import "molecules/insta";
@import "molecules/slide-in";
@import "molecules/accordion";
@import "molecules/inpagenav";
@import "molecules/reveal";
@import "molecules/map";

//Layout
@import "layout/body";
@import "layout/header";
@import "layout/hamburger";
@import "layout/menu";
@import "layout/main";
@import "layout/container";
@import "layout/footer";

//Utilities
@import "utilities/utilities";
