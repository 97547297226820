// html.sr {
//   .fade-in-interval, .fade-in-news, .fade-in-bottom {
//     visibility: hidden;
//   }
// }

.box-shadow {
  box-shadow: 0 7px 10px rgba($black,0.3) ;
}

.c-red {
  color: $red;
}

.bg-brown {
  background-color: $brown;
  color: $white;
  a:not(.accordion-title):not(.card-link a) {
    color: $white;
    text-decoration: underline;
    &:hover {
      opacity: 0.7;
    }
  }
}

.bg-beige {
  background-color: $beige;
  color: $white;
}

.padding-top {
  &--1 {
    padding-top: 1em;
  }
  &--2 {
    padding-top: 2em;
  }
  &--3 {
    padding-top: 3em;
  }
  &--4 {
    padding-top: 4em;
  }
}

.padding-bottom {
  &--1 {
    padding-bottom: 1em;
  }
  &--2 {
    padding-bottom: 2em;
  }
  &--3 {
    padding-bottom: 3em;
  }
  &--4 {
    padding-bottom: 4em;
  }
}