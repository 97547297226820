.insta-item {
  position: relative;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: rgba($black,0.5);
    text-align: center;
    opacity: 0;
    @include font-size(0.9375,1.125);
    transition: opacity .2s ease-in-out;
    > a {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: rem-calc(15);
      color: $white;
      p {
        margin: 0;
      }
    }
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}

.insta-list {
  @include breakpoint (small only) {
    &:nth-of-type(1n+4) {
      display: none;
    }
  }
  @include breakpoint (medium only) {
      &:nth-of-type(1n+5) {
      display: none;
    }
  }
}