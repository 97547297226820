ul:not(.menu):not(.form):not(.accordion):not(.glossary):not(.files) {
  margin-left: rem-calc(14);
  padding-left: 0;
  list-style-type: none;
  li {
    &:before {
      content: "– ";
      margin-left: rem-calc(-14);
      margin-right: 0;
      padding-right: rem-calc(3);
    }
  }
}

ul.glossary {
  border-bottom: 1px solid $brown;
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
  li {
    border-top: 1px solid $brown;
    padding: 2em 0;
    @include font-size(0.9375,1.25);
    display: flex;
    font-weight: 400;
    div:first-child {
      flex: 25%;
      font-weight: 500;
    }
    div:last-child {
      flex: 75%;
      padding-left: rem-calc(10);
    }
  }
}

ul.files {
  position: relative;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include breakpoint(medium) {

  }
  li {
    position: relative;
    width: rem-calc(250);
    max-width: 100%;
    margin: 0 2em 2em 2em;
    @include breakpoint(large) {
      width: rem-calc(350);
    }
    svg {
      max-width: 100%;
      height: auto;
    }
    .text {
      font-weight: bold;
      color: $white;
      text-transform: uppercase;
      text-align: center;
      margin-top: 0.8em;
    }
  }
}