.mainswiper {
  height: calc(100vh - 70px);
  z-index: 4;
  @include breakpoint(large) {
    height: calc(100vh - 96px);
  }
  .swiper-slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.swiper-outer {
  position: relative;
  margin-bottom: 4.5em;
  h1 {
    position: absolute;
    z-index: 9;
    width: 100%;
    top: 50%;
    left: 50%;
    padding: rem-calc(20);
    transform: translate(-50%,-50%);
    color: $white;
    text-align: center;
    text-shadow: 0 2px 10px rgba($black,0.8);
  }
  .arrow-next {
    position: absolute;
    bottom: rem-calc(10);
    z-index: 4;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    .icon {
      fill: $white;
    }
  }
}

.article-wrapper {
  position: relative;
}

.swiper-pagination.swiper-pagination-bullets {
  right: rem-calc(20);
  left: auto;
  bottom: rem-calc(25);
  width: auto;
  .swiper-pagination-bullet {
    width: 0.5em;
    height: 0.5em;
    background-color: $white;
    opacity: 0.6;
    &.swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
}


.swiper-news {
  position: unset;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: -15px;
  margin-right: -15px;
  .swiper-wrapper {
    align-items: stretch;
    .swiper-slide {
      height: auto;
    }
  }
}

.swiper-button-prev {
  transform: translateX(-24px);
  filter: drop-shadow(2px 2px 2px rgba($black,.7));
  &::after {
    content: "";
  }
  .svg-icon {
    transform: rotate(180deg);
    .icon {
      fill: $white;
    }
  }
  @include breakpoint(medium) {
    transform: translateX(-40px);
    filter: drop-shadow(0 0 0 rgba($black,0));
  }
  @include breakpoint(large) {
    transform: translateX(-60px);
  }
  @include breakpoint(1754) {
    transform: translateX(-100px);
  }

}

.swiper-button-next {
  transform: translateX(24px);
  filter: drop-shadow(2px 2px 2px rgba($black,.7));
  &::after {
    content: "";
  }
  .svg-icon {
    .icon {
      fill: $white;
    }
  }
  @include breakpoint(medium) {
    transform: translateX(40px);
    filter: drop-shadow(0 0 0 rgba($black,0));
  }
  @include breakpoint(large) {
    transform: translateX(60px);
  }
  @include breakpoint(1754) {
    transform: translateX(100px);
  }

}

.swiper-history {
  position: unset;
  background-image: url("../images/MLG_Zeitstrahl_Linie_lang.svg");
  background-repeat: repeat-x;
  background-position: 0 150px;

  .swiper-slide {
    &:first-child {
      background: linear-gradient(270deg, rgba(255,255,255,0) 49%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    }
    &:last-child {
      background: linear-gradient(270deg, rgba(255,255,255,1) 49%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
    }
  }

  .svg-icon-img {
    text-align: center;
    padding: 1em;
    img {
      width: rem-calc(252);
      margin: 0 auto;
    }
  }

  .text {
    text-align: center;
    @include font-size(0.9375,1.25);
  }

  .swiper-button-next, .swiper-button-prev {
    filter: drop-shadow(0 0 0 rgba(0,0,0,0));
    top: rem-calc(154);
    .svg-icon .icon {
      fill: $beige;

    }
  }
}