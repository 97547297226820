.legende {
  padding: .5em 0 1em 0;
  @include font-size(1,1.25);
}
.legende-icon {
  margin-right: 0.3em;
  width: rem-calc(30);
  height: rem-calc(30);
  @include breakpoint(xlarge) {
    width: rem-calc(36);
    height: rem-calc(36);
  }
}
.legende-grid {
  display: grid;
  @include breakpoint(medium) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    gap: 10px;

  }
  @include breakpoint(large) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
  }
  .legende-item {
    margin-bottom: rem-calc(10);
  }
}

.kompass {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  img {
    width: rem-calc(40);
    margin-bottom: rem-calc(10);
    @include breakpoint(medium) {
      width: rem-calc(60);
    }
    @include breakpoint(large) {
      width: rem-calc(80);
    }
  }
}

.map-container {
  position: relative;
  #my-map {
    background-color: #F4F4F4;
  }
  .hand {
    display: none;

    position: absolute;
    top: rem-calc(50);
    left: rem-calc(15);
    width: rem-calc(50);
    opacity: 0.45;
    z-index: 2;
    @include breakpoint(large) {
      width: rem-calc(80);
    }
  }
}

.sub-map {
  padding: .8em 0 0.4em 0;
  .alert-icon {
    margin-right: 0.3em;
    transform: translateY(-4px);
  }
  p.c-red {
    @include font-size(0.9375,1.125);
    margin: 0;
    a {
      font-weight: 700;
      color: $red;
      text-decoration: underline;
      &:hover {
        color: $black;
      }
    }
  }
}