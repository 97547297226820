.card {
  height: calc(100% - 2em);
  margin-bottom: 2em;
  position: relative;
  .card-section {
    @include font-size(0.9375,1.125);
  }
  .card-link {
    @include font-size(0.9375,1.125);
    padding: 0 2.2em 2em 2.2em;
    font-weight: 700;
    a, button {
      color: $brown;
      .svg-icon {
        vertical-align: -0.6em;
        font-size: 1.8em;
        margin-left: 0.4em;
        svg {
          fill: $brown;
        }
      }
    }
  }
  &.poi-card {
    //margin-bottom: 0;
    .card-section {
      padding: 1em 1.2em 0 1.2em;
    }
    .card-link {
      padding: 0 1.2em 1.2em 1.2em;
    }
  }

}


.poi-icons {
  position: absolute;
  left: rem-calc(15);
  top: rem-calc(15);
  .number {
    position: absolute;
    right: rem-calc(-9);
    top: rem-calc(-5);
    width: rem-calc(20);
    height: rem-calc(20);
    text-align: center;
    font-weight: 700;
    border-radius: 50%;
    background-color: $white;
    font-size: rem-calc(14);
  }
}