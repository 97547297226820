.hams {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999999;
  display: flex;
}

.h-fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.2s ease-in-out;
  z-index: 101;
  @include breakpoint(large) {
    font-size: rem-calc(18);
  }

  &.opaque {

  }

}

.header-overlay {
  position: absolute;
  width: 100%;
  z-index: 100;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center 44px;
  @include breakpoint(500) {
    background-image: url("../images/header_bg-desktop.svg");
    background-size: 1114px 180px;
    background-repeat: repeat-x;
    background-position: left -44px;
  }
  @include breakpoint(large) {
    background-position: left -25px;
  }

}

.header-underlay {
  background-color: $white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}
